import { Link, Typography } from "@material-ui/core";
import React from "react";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://animooze.com/">
        Animooze, LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export function ApplicationFooter(): JSX.Element {
  return <footer className="application-footer">
    <Copyright />
  </footer>
}
