import React from 'react';
import logo from './logo.svg';
import './App.scss';
import { ApplicationFooter } from './components/global/footer';

/*

Palette:

Purple: #7400b8 116,0,184
Purple2: #6930c3 105,48,195
Purple3: #5e60ce 94,96,206
Bluish1: #5390d9 83,114,217
Bluish2: #4ea8de 78,168,222

NeonBlue: #48bfe3 72,191,227
NeonBlue2: 56cfe1 86,207,225
NeonBlue3: #64dffd 100,223,223
NeonBlue4: #72efdd 114,239,221
NeonBlue5: #80ffdb 128, 255, 219

*/

function App(): JSX.Element {
  return (
    <div>
      <div className="App">
        <header className="App-header">
          Animooze
        </header>
        <h4>Bringing big data to small business</h4>
        <ApplicationFooter />
      </div>
    </div>
  );
}

export default App;
